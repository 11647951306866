import React from 'react';
import classes from './App.module.css'
import { Link } from 'react-router-dom'
import SoloLearn from './sololearn/sololearn.js'
const Navigation = (props) => {
    return (

        <div className={classes.column}>
            <Link to="/">Home</Link><br /><br />
            {props.loggedin ? <>
                <Link to={{
                    pathname: "/private",
                }}>UserPage</Link><br />
                <Link to="/chat">Chat</Link><br />
                <Link to="/hitthenail">Hit The Nail</Link><br />
            </> :
                <><Link to="/login">Login</Link><br />
                    <Link to="/signup">SignUp</Link><br /><br />
                </>
            }

            {/* Navigation Area */}
            <br />
            <Link title="Made with React" to="/ConwaysLife">Conway's Life</Link><br /><br />
            <h1>Sketches</h1>
            <Link to="/NightSky">NightSky</Link><br />
            <Link to="/RainyNight">RainyNight</Link><br />
            <Link to="/Apocalypse">Apocalypse</Link><br />
            <SoloLearn />
            <h1>Games</h1>
            <a target="new" href="https://www.fishmissile.net/ZomBalls">ZomBalls</a>
            <br />
            <h1>More</h1>
            <a target="new" title="My GitHub" href="https://github.com/FishMissile">GitHub</a>
            <br />
            <a target="new" onClick={() => window.open("https://www.fishmissile.net/TCTools", "TCTools", "width=460,height=500")} href="https://www.fishmissile.net/TCTools">TCTools</a>
            <br />
            <a target="new" href="https://www.jaggedcommandos.net">JaggedCommandos</a>
        </div>
    );
}

export default Navigation;
