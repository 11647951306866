import React, { Component } from 'react';
import Universe from './universe.js'
import classes from '../App.module.css'
import './css.css';

class Cell extends Component {
    render() {
        return (
            <div onClick={() => this.props.storeCell(this.props.position)} className={this.props.live ? "cellContainerLive" : "cellContainerDead"}></div>
        );
    }
}


export default class ConwaysLife extends Component {
    constructor(props) {
        super(props)
        this.state = { size: [85, 50], gameRunning: false, universe: new Universe(), interval: 100 }

        this.startGame = this.startGame.bind(this);
        this.stopGame = this.stopGame.bind(this);
        this.renderBoard = this.renderBoard.bind(this);
        this.storeCell = this.storeCell.bind(this);
        this.changeInterval = this.changeInterval.bind(this)
        this.randomize = this.randomize.bind(this)
        this.clear = this.clear.bind(this)
    }
    runGame() {
        this.setState({
            universe: this.state.universe.addGeneration()
        })
    }

    storeCell(position) {
        if (!this.state.gameRunning) {
            this.setState({
                universe: this.state.universe.storeCell(position)
            })
        }
    }


    changeInterval(event) {
        if (!this.state.gameRunning) {
            this.setState({
                interval: event.target.value
            })
        }
    }

    startGame() {
        if (!this.state.gameRunning) {
            this.setState({
                gameRunning: true,
            }, () => {
                this.intervalRef = setInterval(() => this.runGame(), this.state.interval);
            })
        }
    }

    stopGame() {
        this.setState({
            gameRunning: false
        }, () => {
            if (this.intervalRef) {
                clearInterval(this.intervalRef);
            }
        })
    }

    renderBoard() {
        var newWorld = [];
        var cellRow = [];

        for (var i = 0; i < this.state.size[0]; i++) {
            for (var j = 0; j < this.state.size[1]; j++) {
                if (this.state.universe.isCellAlive(i + " , " + j)) {
                    cellRow.push(
                        <Cell key={[i, j]} position={{ x: i, y: j }} live={true} storeCell={this.storeCell.bind(this)} />
                    );
                } else {
                    cellRow.push(
                        <Cell key={[i, j]} position={{ x: i, y: j }} live={false} storeCell={this.storeCell.bind(this)} />
                    );
                }
            }
            newWorld.push(<div className="row" key={i}>{cellRow}</div>);
            cellRow = [];
        }

        return newWorld;
    }

    randomize() {
        for (var i = 0; i <= this.state.size[0]; i++) {
            for (var j = 0; j <= this.state.size[1]; j++) {
                if (Math.random() < 0.2) {
                    this.storeCell({ x: i, y: j })
                }
            }
        }
    }

    clear() {

        this.setState({ size: [85, 50], gameRunning: false, universe: new Universe(), interval: 100 })
    }

    render() {
        const cssStyles = [classes.input, classes.view ]
        return (
            <div className={cssStyles.join(' ')}>
                <h2>Conway's Life</h2>
                <div className="worldContainer">
                    <div className="headerContainer">
                        <div className="headerInnerContainer">
                            <label className="label">
                                Interval:<input className="input" type="text" value={this.state.interval} onChange={this.changeInterval} />
                            </label>
                        </div>
                        <div className="headerButtons">
                            <button className="submit" onClick={this.startGame}>Start</button>
                            <button className="submit" onClick={this.stopGame}>Stop</button>
                            <button className="submit" onClick={this.randomize}>Random</button>
                            <button className="submit" onClick={this.clear}>Clear</button>
                        </div>
                    </div>
                    <hr/>
                    <div className="boardContainer">
                        {this.renderBoard()}
                    </div>
                    <hr />
                   <p>
                    Any live cell with two or three neighbors survives as if by overpopulation.<br/>
                    Any dead cell with three live neighbors becomes a live cell as if by reproduction.<br />
                    All other live cells die in the next generation. Similarly, all other dead cells stay dead.<br /> <br/>
                    I originally made Conway's Life with jQuery but did not feel like going through the complexities <br />
                    of also doing it in React so I followed <a target="new" className={classes.link} href="https://revs.runtime-revolution.com/implementing-conways-game-of-life-with-react-part-1-c0b974ae33eb">this guide</a>.</p>
                </div>
            </div>
        );
    }
}

