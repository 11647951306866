import React, { Component } from 'react'
import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketch.js'


export default class SketchComonent extends Component {
    constructor(props) {
        super(props)
        this.state = { rotation: 0.1 }
    }
    componentDidMount() {
        this.interval = setInterval(() => {

            let rotation = this.state.rotation
            rotation += 0.2

            this.setState({
                rotation: rotation++
            })
        }, 1)
    }

    //WARNING! To be deprecated in React v17. Use componentDidUpdate instead.
    componentWillUnmount(nextProps, nextState) {
        clearInterval(this.interval)
    }
    render() {
        return (
            <div >
                <P5Wrapper sketch={sketch} rotation={this.state.rotation} />
            </div>
        )
    }
}
