import React from 'react';
import classes from './App.module.css';

const UserPage = (props) => {
    return (
        <div className={classes.view}>
            <h2>User Page</h2>
            <br />
            <button onClick={props.handleLogout}>Sign out</button>
            <br />
            <br />
            Welcome {props.username} to your user page! Thanks for registering on the site.
            <br />
            <br />
            Points: {props.points}
            <br />
            <br />
            <form onSubmit={(e) => props.handleName(e)}>
                <label className={classes.center}>Username:
                     <input maxLength="15" name="username" type="username" ></input>
                </label>
                <label className={classes.center}>
                    <button type="submit" >
                        Change
                    </button>
                </label>
            </form>
        </div>
    );
}

export default UserPage;
