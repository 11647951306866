import React, { PureComponent } from 'react';
import classes from './App.module.css';


class Notifications extends PureComponent {

    render() {
        let box = classes.Modal
        let css = [box, this.props.open ? classes.ModalOpen : classes.ModalClosed]
        return (
            <div className={css.join(' ')}>
                <p> {this.props.content} </p>
            </div>
        );
    }

}

export default Notifications;
