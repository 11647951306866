import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";

//Private routes can pass on props as data={{stateOne: OneState, stateTwo: TwoState} }
const PrivateRoute = ({ component: Component,data, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    
    return (
        <Route {...rest} render={props =>
                !!currentUser ? (
                    <Component {...props} {...data} />
                ) : (
                        <Redirect to={"/login"} />
                    )
            }
        />
    );
};

export default PrivateRoute