import React, { PureComponent } from 'react'


export default class SoloLearn extends PureComponent {
    render() {

        return (
            <div>
                <h1>SoloLearn</h1>
                <a target="new" href="https://code.sololearn.com/Wc40094zMD04/">Snake</a><br />
                <a target="new" href="https://code.sololearn.com/WQ0UCOMAv20Q/">MineSweeper</a><br />
                <a target="new" href="https://code.sololearn.com/WEcf0e7BTdFi/">Conway's Life (jQuery)</a><br />
                <a target="new" href="https://code.sololearn.com/W0pSiS4UPt1V/">Dual Sticks (mobile)</a><br />
            </div>
        )
    }
}
