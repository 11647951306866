
import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./Auth";
import fireB from './firebase.js';
import classes from './App.module.css';

//Components
import Banner from './images/banner.png';
import Notifications from './notifications.js';
import Navigation from './navigation.js';
import MainView from './mainview/mainview.js';



export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = { loggedin: false, uid: null, username: null, email: null, points: null, data: "some data", open: false, content: "If you see this, Fishy made a mistake..." }
    this.handleLogout = this.handleLogout.bind(this)
    this.handleName = this.handleName.bind(this)
    this.setName = this.setName.bind(this)
    this.handlePoints = this.handlePoints.bind(this)
    this.newNotification = this.newNotification.bind(this)
  }
  componentDidMount() {
    console.log("Initializing...")
    fireB.auth().onAuthStateChanged(function (user) {
      console.log("Auth State Chage")
      let points = 0
      if (user) {
        // User is signed in.
        var db = fireB.firestore();
        var docRef = db.collection("users").doc(user.uid);
        docRef.get().then(function (doc) {
          const { points } = doc.data()
          if (doc.exists) {
            this.setState({ points: points })
          } else {
            // doc.data() will be undefined in this case
            this.newNotification("No such document!")
          }
        }.bind(this)).catch(function (error) {
          console.log("Error getting document:", error);
          this.newNotification("Error getting document:" + error)
        });

        let displayName = user.displayName;
        let email = user.email;
        let uid = user.uid
        this.setState({ loggedin: true, uid: uid, username: displayName, email: email, points: points })
      }
    }.bind(this));
    console.log("AppInitialized")
  }

  handleLogout() {
    this.setState({ loggedin: false })
    fireB.auth().signOut()
    this.newNotification("You have been logged out.")
  }

  setName(name) {
    this.setState({
      username: name,
      loggedin: true,
    })
  }

  handleName(e) {
    e.preventDefault()
    const { username } = e.target.elements;
    const user = fireB.auth().currentUser

    user.updateProfile({
      displayName: username.value,
    }).then(function () {
      this.setState({ username: username.value })
      this.newNotification("Username successfully changed.")
    }.bind(this)).catch(function (error) {
      this.newNotification("Username has not been changed, something went wrong, but it probably wasn't Fishy's fault.")
    });
  }

  newNotification(content) {
    this.setState({ open: true, content: content })
    setTimeout(() => {
      console.log("Automatic Close")
      this.setState({ open: false })
    }, 4000);
  }

  handlePoints(points) {
    let currentPoints = this.state.points
    currentPoints += points
    const user = fireB.auth().currentUser
    var db = fireB.firestore();
    db.collection("users").doc(user.uid).set({
      name: this.state.username,
      points: currentPoints,
    })
      .then(function () {
        console.log("Document successfully written!");
      })
      .catch(function (error) {
        console.error("Error writing document: ", error);
      });
    user.updateProfile({
      points: points,
    }).then(function () {

      this.setState({ points: currentPoints })
      this.newNotification("Congratulations! You have won " + points + " points!")
      console.log("Points Updated: " + points)
    }.bind(this)).catch(function (error) {
      this.newNotification("Something went wrong, your points have not been saved.")
    });
  }

  render() {
    return (
      <AuthProvider>
        <Router>
          <div className={classes.App}>

            <Navigation loggedin={this.state.loggedin} />

            {/* //Main Viewport */}
            <div className={classes.vert}>FishMissile.net</div>
            <div className={classes.mid}>
              <Notifications open={this.state.open} content={this.state.content} />
              <div className={classes.banner}><img alt="banner" src={Banner}></img>

              </div>
              <MainView
                newNotification={(n) => this.newNotification(n)}
                handleLogout={this.handleLogout}
                handleName={this.handleName}
                setName={this.setName}
                username={this.state.username}
                points={this.state.points}
                handlePoints={(p) => this.handlePoints(p)}
                uid={this.state.uid}
              />
            </div>
          </div>
        </Router>
      </AuthProvider>
    )
  }
}

