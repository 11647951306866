import React, { useCallback, useContext } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import fireB from "./firebase.js";
import { AuthContext } from "./Auth.js";
import classes from './App.module.css'

const Login = (props) => {
    const { history } = props
    const handleLogin = useCallback(
        async event => {
            console.log("EVENT handleLogin TRIGGERD")
            event.preventDefault();
            const { email, password } = event.target.elements;
            try {
                await fireB
                    .auth()
                    .signInWithEmailAndPassword(email.value, password.value);
                history.push("/private");
            } catch (error) {
                props.newNotification(error.toString())
            }
        },
        [history,props]
    );

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        return <Redirect to="/private" />;
    }

    return (
        <div className={classes.view}>
            <h1>Log in</h1>
            <form  onSubmit={handleLogin}>
                <br/>
                <label className={classes.center}>
                    Email
          <input name="email" type="email" maxLength="30"   placeholder="Email" />
                </label>
                <label className={classes.center} >
                    Password
          <input name="password" type="password" maxLength="30" placeholder="Password" />
                </label>
                <label className={classes.center} >
                    <button type="submit">Log in</button>
                </label>
                <br />
                <label className={classes.center} >
                <Link to="/signup">Sign Up</Link>
                </label>
            </form>
        </div>
    );
};

export default withRouter(Login);