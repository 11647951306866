import React, { Component } from 'react'
import classes from '../App.module.css'
import './css.css'
import fireB from '../firebase'

export default class Chat extends Component {
    constructor(props) {
        super(props)
        this.state = { chatlog: null }
        this.initialized = false
        this.addLine = this.addLine.bind(this);
    }
    componentDidMount() {
        if (this.initialized === false) {
            let database = fireB.database();
            let data = database.ref('chatlog/').limitToLast(20)

            let AllData
            // Contact firebase and retrieve current pph and finishtime
            data.on('value', function (snap) {
                AllData = snap.val()
                let chatArray = []
                for(let key in AllData){
                    const newLine = {
                        ...AllData[key],
                        key:key
                    }
                    chatArray.push(newLine)
                }
                 this.setState({
                    chatlog: chatArray,
                })
            }.bind(this))
        }
        this.boxRef.scrollTop = this.boxRef.scrollHeight + 10
        this.initialized = true
    }

    addLine(e) {
        e.preventDefault()

        var chatRef = fireB.database().ref('chatlog/');
        var newPostRef = chatRef.push();
        newPostRef.set({
            text: e.target.elements.chatline.value,
            username: this.props.username,
        });
        this.boxRef.scrollTop = this.boxRef.scrollHeight + 10
        this.formRef.reset();
    }
    render() {
        return (
            <div className={classes.view}>
                <h2>Live Chat</h2>
                <div ref={(el) => this.boxRef = el}  className="chatbox">
                    <div>
                    {
                        this.state.chatlog ? this.state.chatlog.map((m)=>{
                            return  <div key={m.key} className="chatline"><p className="username">{m.username}</p>: {m.text}</div>
                        }): null
                    }
                    </div>
                </div>
                <form ref={(el) => this.formRef = el} onSubmit={this.addLine}>
                    <button type="Submit">Submit</button><input className="input" maxLength="256"  autoComplete="off" name="chatline" ></input>
                </form>
            </div>
        )
    }
}
