import React, { Suspense, Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";

import PrivateRoute from "../PrivateRoute";

import Welcome from '../welcome/welcome';
import ConwaysLife from '../conwayslife/conwayslife.js';

import UserPage from "../userpage.js";
import Login from "../login.js";
import SignUp from "../signup.js";
import HitTheNail from '../hitthenail/hitthenail.js';
import Chat from '../chat/chat.js';
import PageMissing from '../404.js'

const NightSky = React.lazy(() => import('../cities/nightsky/nightsky.js'))
const RainyNight = React.lazy(() => import('../cities/rainynight/rainynight.js'))
const Apocalypse = React.lazy(() => import('../cities/apocalypse/apocalypse.js'))

class MainView extends Component {
    constructor(props) {
        super(props)
        this.state = { activeSketch: null }
    }

    componentDidUpdate(prevProps) {
        const pathname = this.props.location.pathname
        if (prevProps.location.pathname !== pathname) {
            switch (pathname) {
                case "/RainyNight":
                    this.setState({ activeSketch: pathname })
                    break;                
                case "/NightSky":
                    this.setState({ activeSketch: pathname })
                    break;
                case "/Apocalypse":
                    this.setState({ activeSketch: pathname })
                    break;
                default:
                    break;
            }
        }

    }

render() {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Welcome} />
                <PrivateRoute exact
                    path="/private"
                    component={UserPage}
                    data={{
                        handleLogout: this.props.handleLogout,
                        handleName: this.props.handleName,
                        username: this.props.username,
                        points: this.props.points
                    }} />
                <PrivateRoute exact path="/chat" component={Chat}
                    data={{ username: this.props.username }} />
                <PrivateRoute exact path="/hitthenail" component={HitTheNail}
                    data={{
                        handlePoints: p => this.props.handlePoints(p),
                        newNotification: n => this.props.newNotification(n),
                        ChangeData: this.props.ChangeData,
                        username: this.props.username,
                        uid: this.props.uid
                    }} />
                <Route exact path="/login" render={(routeProps) => (<Login {...routeProps} newNotification={this.props.newNotification} />)} />
                <Route exact path="/signup" render={(routeProps) => (<SignUp {...routeProps} newNotification={this.props.newNotification} setName={this.props.setName} />)} />

                <Route exact path="/ConwaysLife" component={ConwaysLife} />

                <Route path="/Apocalypse" render={() => <Suspense fallback={<div>Loading...</div>}>
                    <Apocalypse activeSketch={this.state.activeSketch} />
                </Suspense>} />

                <Route path="/NightSky" render={() => <Suspense fallback={<div>Loading...</div>}>
                    <NightSky activeSketch={this.state.activeSketch} />
                </Suspense>} />

                <Route path="/RainyNight" render={() => <Suspense fallback={<div>Loading...</div>}>
                    <RainyNight activeSketch={this.state.activeSketch} />
                </Suspense>} />
                <Route path="/404" component={PageMissing} />
                <Route component={PageMissing} />
            </Switch>
        </div>
    )
}

}

export default withRouter(MainView);
