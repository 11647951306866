import image from '../images/FishMissile1.jpg'

export default function sketch(p) {
   // let rotation = 0;
    let img
    p.setup = function () {
        p.createCanvas(200, 200, p.WEBGL);
        img = p.loadImage(image);
    };

    p.myCustomRedrawAccordingToNewPropsHandler = function (props) {
        if (props.rotation) {
           // rotation = props.rotation * Math.PI / 180;
        }
    };

    p.draw = function () {
        p.background(0,0.02);
        
       
        p.stroke(0,180,0)
        p.push();
        p.specularMaterial(250); 
        p.normalMaterial();
        p.pointLight(100, 255, 200, 500, 0, 200);
        p.directionalLight(100, 255,10, -1, 0, 0);
        p.pointLight(100, 100, 250, 0, 0, 300);
        p.rotateY(p.mouseY  / 100);
        p.rotateX(p.mouseX  / 100);
        p.texture(img)
        p.box(100);
        p.pop();
    };
};