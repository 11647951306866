import React, { Component } from 'react'
import classes from '../App.module.css'
import './css.css'
import fireB from '../firebase.js'

export default class HitTheNail extends Component {

    constructor(props) {
        super(props)
        this.state = { currentNail: 99, lastHit: " ",lastHitID: null, winner: " ", hitCount: 0, topHitters: [] }
        this.initialized = false
        this.Hit = this.Hit.bind(this)
    }

    componentDidMount() {
        if (this.initialized === false) {
            console.log("Initializing Nail")
            let database = fireB.database();
            let data = database.ref('nail/')
            var db = fireB.firestore();

            // retrieve top 5 hitters sorted by score in descending order
            db.collection("users").orderBy("points", "desc").limit(5).onSnapshot(function (doc) {
                let topHitters = []
                doc.forEach((snap) => {
                    if (snap.exists) {
                        // Create an array with unique ID object from firebase collection
                        topHitters.push({...snap.data(), key: snap.id})
                    }
                })
                
                this.setState({ topHitters: topHitters })

            }.bind(this));
            let AllData

            // Contact firebase and retrieve current nail info
            data.on('value', function (snap) {
                AllData = snap.val()
                const { currentNail, lastHit, winner, hitCount, lastHitID } = AllData

                this.setState({
                    lastHit: lastHit,
                    lastHitID: lastHitID,
                    currentNail: currentNail,
                    winner: winner,
                    hitCount: hitCount,
                })
            }.bind(this))
        }
        this.initialized = true
    }

    componentWillUnmount() {   
        let database = fireB.database();
        let data = database.ref('nail/')
        data.off()
        console.log("Unsubscribed to Nail")
    }

    Hit() {
        console.log("Nail Hit")
        let nailRef = fireB.database().ref('nail/');
        let newNail = this.state.currentNail
        let newCount = this.state.hitCount
        newCount += 1
        console.log("Last Hit: " ,this.state.lastHitID)
        console.log("Current User ID: ", this.props.uid)
        if (this.state.lastHitID !== this.props.uid) {
            console.log("IDS MATCH")
            newNail -= Math.random() * 13

            if (Math.floor(newNail) <= 0) {
                console.log(" RESET NAIL")
                this.props.handlePoints(newCount)
                nailRef.set({
                    lastHit: this.props.username,
                    lastHitID: this.props.uid,
                    currentNail: 99,
                    winner: this.props.username,
                    hitCount: 0,
                });

                this.setState({
                    lastHit: this.props.username,
                    lastHitID: this.props.uid,
                    currentNail: 99,
                    winner: this.props.username,
                    hitCount: 0,
                })
            } else {
                nailRef.update({
                    lastHit: this.props.username,
                    lastHitID: this.props.uid,
                    currentNail: newNail,
                    hitCount: newCount
                });
                this.setState({
                    lastHit: this.props.username,
                    lastHitID: this.props.uid,
                    currentNail: newNail,
                    hitCount: newCount
                })
            }
        } else {
            this.props.newNotification("You cannot hit the nail twice in a row. Please wait for someone else to hit.")
        }
    }

    render() {
        return (
            <div className={classes.view}>
                <h2>Hit The Nail</h2>
                <br/>
                Take turns hitting the nail. Whoever hits it all the way to 0% wins a point for each hit of the nail.
                 <br />
                <br /><p className="big">Last Winner: {this.state.winner}</p>
                <br />
                <br />
                <button onClick={this.Hit}>Hit The Nail</button>
                <br />
                <br />
                <p className="big">Hit Count: {this.state.hitCount}</p>
                <br /> 
                <p className="nail">Nail:</p><p className="percent">{Math.floor(this.state.currentNail)}%</p>
                <br />
                <br />
                <p className="big">Last Hit: {this.state.lastHit}</p>
                <br />
                <br />
                <p className="big">Top Hitters:</p>
                <br/>
                {this.state.topHitters.map((hitter) => {
                    return( <div key={hitter.key}>{hitter.name} : {hitter.points}</div>)
                })}
                <br />
            </div>
        )
    }
}
