import React, { useCallback } from "react";
import { withRouter } from "react-router";
import fireB from "./firebase.js";
import classes from './App.module.css';

const SignUp = (props) => {
    const { history } = props
    const handleSignUp = useCallback(async (event, setName) => {
        event.preventDefault();
        const { email, username, password, password2 } = event.target.elements;
        if (password.value === password2.value) {
            try {
                await fireB
                    .auth()
                    .createUserWithEmailAndPassword(email.value, password.value)
                    .then((user) => {
                        console.log(user)
                        if (user) {
                            user.user.updateProfile({
                                displayName: username.value,
                                points: 0
                            }).then(() => {
                                console.log("SIGN UP: USER NAME SET: " + username.value)
                                setName(username.value)
                                history.push("/private");
                            })
                        }
                    })
            } catch (error) {
                props.newNotification(error.toString())
            }
        } else {
            props.newNotification("Passwords do not match")
        }
    }, [history,props]);

    return (
        <div className={classes.view}>
            <h1>Sign up</h1>
            <br/>
            <form onSubmit={(e) => handleSignUp(e, props.setName)}>
                <label className={classes.center} >
                    Email:
                    <input name="email" maxLength="30" type="email" placeholder="Email" />
                </label>
                <label className={classes.center}>
                    Username:
                    <input name="username" maxLength="15" type="username" placeholder="username"></input>
                </label>
                <label className={classes.center}>
                    Password:
                    <input name="password" maxLength="30" type="password" placeholder="Password" /><br />
                    </label>
                <label className={classes.center} >
                    Password:
                    <input name="password2" maxLength="30" type="password" placeholder="Password" />
                </label>
                <br/>
                <label className={classes.center} >
                    <button type="submit">Sign Up</button>
                </label>
            </form>
        </div>
    );
};

export default withRouter(SignUp);