import React, { PureComponent } from 'react';
import classes from '../App.module.css';
import './css.css'
import SketchComponent from './sketchcomponent.js'

class Welcome extends PureComponent {

    render() {
        return (
            <div className={classes.view}>
                <h2>Welcome</h2>
                <p>Welcome to FishMissile.net, the homepage of FishMissile. Just a place to show off all of my coding projects in one place and a playground for me to fiddle with. There isn't much here yet, but check back often!</p>
                <hr />
                <div className="construction">[UNDER MUCH CONSTRUCTION]</div>
                <SketchComponent />
                Powered by <a href="https://reactjs.org/" target="new">React</a>, <a href="https://firebase.google.com/" target="new">Firebase</a> and <a href="https://p5js.org/" target="new">p5</a>
            </div>
        );
    }

}

export default Welcome;
