import React from 'react';
import Image404 from '../src/images/404.jpg'
import classes from './App.module.css'

const PageMissing = () => {
    return (
        <div className={classes.view}>
            <h2>404 Page Not Found</h2>
            <br/>
            This page does not exist. Go somewhere else.
            <br />
            <br />
            <img className={classes.e404} src={Image404} alt="404 Page Not Found"></img>                
            <br/>
        </div>
    );
}

export default PageMissing;
